;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"10bde61bc77fd9120f4d7ec0f41b76b081ed484c"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/plp";import * as Sentry from '@sentry/nextjs';

const {
    initSentry,
} = require('../../libs/common-next/shared-sentry.client.config');

initSentry({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

    initialScope: {
        tags: {
            application_name: 'PLP',
        },
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate:
        Number(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE) || 0.015,

    // NOTE: the BrowserTracing and Replay was commented out to avoid performance issue
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error'],
        }),
    ],
});

export default Sentry;
